import "./PortfolioItem.scss"

import Portfolio1 from "../../images/portfolio-1.webp"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const PortfolioItem = (props) => {

const {img, title, desc, tag1, tag2} = props;

  return (
    <div className="portfolio-item p-3 pb-4">

      <img src={img} className="w-100 mb-3" alt={title} />

      <div className="d-flex mb-3">
        <span className="tag me-2">{tag1}</span>
        <span className="tag">{tag2}</span>
      </div>

       <h3 className="m-0">{title}</h3>
       {/* <p>{desc}</p> */}

    </div>
  )
}
