/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import { Footer } from "../../components/Footer/Footer"
import NavBar from "../../components/NavBar/NavBar"

const LayoutIndex = ({ children }) => {
  return (
    <div class="flex min-h-screen flex-col justify-between bg-neutral-50 text-neutral-900">
      <div>
        <NavBar />
        {children}
        <Footer />
      </div>
    </div>

  )
}


export default LayoutIndex
