import "./index.scss"

import * as React from "react"
import * as particleSettings from "./particlesjs-config.json"

import ContactForm from "../components/ContactForm/ContactForm"
import DesignIcon from "../images/pen-ruler-solid.svg"
import LaptopIcon from "../images/laptop-solid.svg"
import LayoutIndex from "../components/Layouts/LayoutIndex"
import MobileIcon from "../images/mobile-screen-button-solid.svg"
import Particles from "react-tsparticles"
import Portfolio1 from "../images/portfolio-1.webp"
import Portfolio2 from "../images/portfolio-2.webp"
import Portfolio3 from "../images/portfolio-3.webp"
import Portfolio4 from "../images/portfolio-4.webp"
import Portfolio5 from "../images/portfolio-5.webp"
import Portfolio6 from "../images/portfolio-6.webp"
import { PortfolioCounter } from "../components/PortfolioCounter/PortfolioCounter"
import { PortfolioItem } from "../components/PortfolioItem/PortfolioItem"
import Seo from "../components/SEO/Seo"
import ServiceBlock from "../components/ServiceBlock/ServiceBlock"
import { StackGrid } from "../components/StackGrid/StackGrid"
import { StaticImage } from "gatsby-plugin-image"
import TestimonialScroller from "../components/TestimonialScroller/TestimonialScroller"
import { loadFull } from "tsparticles"
import { useCallback } from "react"

const IndexPage = () => {
  const particlesInit = useCallback(async engine => {
    console.log(engine)
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async container => {
    await console.log(container)
  }, [])

  return (
    <LayoutIndex>
      <Seo
        url="https://attaro.co.uk/"
        title="Attaro | We Deliver the Software You Need"
        description="Welcome to Attaro, where we offer and develop a range of enhanced software solutions to take your business to the next level."
      />

      <section className="container-fluid hero-section">
        <Particles
          id="tsparticles"
          options={particleSettings}
          init={particlesInit}
          loaded={particlesLoaded}
        />

        <div className="container">
          <div className="inner-content col-10 col-md-7">
            <h1 className="hero-heading mb-5">
              Delivering the <span className="white">software</span> you need.
            </h1>
            <div className="d-flex flex-column flex-md-row">
              <a
                href="/contact/"
                className="btn btn-primary mb-4 mb-md-0 me-md-4"
              >
                Contact Us
              </a>
              <a href="#careers" className="btn btn-outline">
                Careers
              </a>
            </div>
          </div>

          <div class="mouse_scroll">
            <div class="mouse">
              <div class="wheel"></div>
            </div>
            <div>
              <span class="m_scroll_arrows unu"></span>
              <span class="m_scroll_arrows doi"></span>
              <span class="m_scroll_arrows trei"></span>
            </div>
          </div>
        </div>
      </section>

      <section className="container container-padding">
        <div className="row align-items-end">
          <div className="col-12 col-md-6">
            <hr className="hr-primary mb-3" />
            <h1 className="mb-4">We are Attaro.</h1>
            <h4 className="mb-4">
              Based in the North West, the Attaro team have been engineering
              innovative, market-leading software for over 15 years. Working
              closely with our clients we create technical solutions to overcome
              business challenges and deliver value.
            </h4>
          </div>
          <div className="col-12 col-md-6">
            <p>
              Our extensive portfolio of development for the heating and
              plumbing industry places us at the forefront of providers in the
              sector and we are proud to have established and lasting
              relationships with our clients.
            </p>
            <p>
              Whatever your requirement, from consumer mobile apps to internal
              business platforms we are here to help. We deliver the software
              you need.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid container-padding bg-navy white">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <hr className="hr-primary mx-auto mb-3" />
              <h1 className="mb-5">Our services</h1>
            </div>
          </div>
          <div className="row services-row">
            <div className="col-12 col-md-4">
              <ServiceBlock
                title={`UX / UI Design`}
                icon={DesignIcon}
                text="Delivering stand out design: visually engaging, user friendly and functional"
              />
            </div>
            <div className="col-12 col-md-4">
              <ServiceBlock
                title="Bespoke Software Development"
                icon={LaptopIcon}
                text="Launching market leading apps: seamless applications across iOS and Android"
              />
            </div>
            <div className="col-12 col-md-4">
              <ServiceBlock
                title="Bespoke Mobile Development"
                icon={MobileIcon}
                text="Providing future proof systems:  front and back end development for internal or B2B platforms"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container container-padding">
        <div className="row">
          <div className="col-12 text-center">
            <hr className="hr-primary mx-auto mb-3" />
            <h1 className="mb-4">We're proud of our work.</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <PortfolioCounter />
          </div>
        </div>
      </section>

      <section className="container-fluid portfolio-section">
        <div className="container grid-container">
          <div className="row portfolio-grid">
            <div className="col-12 col-md-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio1}
                tag1="B2B Platform"
                title="TradeHelp Office"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
            <div className="col-12 col-md-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio2}
                tag1="Mobile"
                title="Ice Cream Farm"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
            <div className="col-12 col-lg-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio3}
                tag1="B2B Platform"
                tag2="Website"
                title="BuyTrade"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
            <div className="col-12 col-lg-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio4}
                tag1="B2B Platform"
                title="Attaro Broker"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
            <div className="col-12 col-lg-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio5}
                tag1="Web App"
                tag2="Portal"
                title="Client Portal"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
            <div className="col-12 col-lg-4 col-sm-6 my-3">
              <PortfolioItem
                img={Portfolio6}
                tag1="B2B Platform"
                tag2="Portal"
                title="Job Management Portal"
                desc="Lorem ipsum dolor sit amet, consectetur"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container container-padding">
        <div className="row">
          <div className="col-12 text-center">
            <hr className="hr-primary mx-auto mb-3" />
            <h1 className="mb-5">Full stack at your disposal.</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <StackGrid />
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="container container-padding">
          <div className="row justify-content-between white">
            <div className="col-12 col-md-5">
              <hr className="hr-light" />
              <h2 className="mb-5">
                For a chat about your project and aspirations get in touch
                today. No cost, no commitment. Let’s see how we can help!
              </h2>
              <h4 className="m-0">Email Us</h4>
              <a
                href="mailto:info@attaro.co.uk"
                className="contact-detail d-block mb-4"
              >
                info@attaro.co.uk
              </a>
              <h4 className="m-0">Call Us</h4>
              <a href="tel:0330120010" className="contact-detail d-block">
                0330 120 0120
              </a>
            </div>
            <div className="col-12 col-md-6 pt-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      <section className="container container-padding" id="careers">
      <div className="row">
      <hr className="hr-primary mb-3" />
            <h1 className="mb-4">Careers.</h1>
      </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mb-4 mt-1">
              Attaro is a small but growing business, boasting a great team of
              individuals who are passionate about what they do.
              <br />
              <br />
              We are currently recruiting for a number of roles and would love
              to hear from you. We offer competitive rates, flexible remote
              working, career progression and a supportive environment.
            </h4>
          </div>
          <div className="col-12 col-md-6 mb-lg-5">
            <h2 className="p-0 mb-0">
              If you are looking for an exciting new challenge, please send your
              CV to{" "}
              <a className="careers-email" href="mailto:martin@attaro.co.uk">
                martin@attaro.co.uk
              </a>{" "}
              for further information.
            </h2>
          </div>
        </div>
      </section>

      {/* <section className="container container-padding">
        <div className="row">
          <div className="col-12 text-center">
            <hr className="hr-primary mx-auto mb-3" />
            <h1 className="mb-3">What our clients say.</h1>
          </div>
        </div>
        <div className="row">
          <TestimonialScroller />
        </div>
      </section> */}
    </LayoutIndex>
  )
}

export default IndexPage
